
























import Vue from 'vue';
import { mapGetters } from 'vuex';
import { HollowDotsSpinner } from 'epic-spinners';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  components: { HollowDotsSpinner },
  data() {
    return {
      now: new Date(),
      isSetup: false,
    };
  },
  created() {
    // Update current time every 10s
    setInterval(() => {
      this.now = new Date();
      this.checkForStatus();
    }, 10000);
  },
  async mounted() {
    await this.setupSession();
    await this.setDeafultLang();
    this.$store.dispatch('viewer/setHideHeader', {
      value: this.$route.query.hideHeader === 'true',
    });
    this.$store.dispatch('viewer/setHideReactions', {
      value: this.$route.query.hideReactions === 'true',
    });
  },
  computed: {
    ...mapGetters({
      isSessionValid: 'viewer/isSessionValid',
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
      isUserAuthenticated: 'auth/isUserAuthenticated',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    isGreenscreenEnabled(): boolean {
      if (this.activeSession) {
        return (
          this.sessionTheme.isGreenscreenFeatureEnabled &&
          this.activeSession.showGreenscreen &&
          this.$route.path.includes('tv')
        );
      }
      return false;
    },
  },
  watch: {
    async isUserAuthenticated() {
      await this.setupSession();
    },
  },
  methods: {
    async setDeafultLang() {
      if (this.$route.query.lang === 'de') {
        await this.$store.dispatch('lang/setLanguage', 'de');
      } else if (this.$route.query.lang === 'en') {
        await this.$store.dispatch('lang/setLanguage', 'en');
      }
    },

    async setupSession() {
      if (!this.isUserAuthenticated) {
        return;
      }

      if (this.isSetup) {
        return;
      }

      // Remove intercom for authenticated users on viewer mode
      window.Intercom('shutdown');

      await this.$store.dispatch('viewer/isSessionValid', {
        sessionToken: this.$route.params.sessionId,
        goToSession: false,
      });

      await this.$store.dispatch('viewer/fetchSessionTheme', {
        sessionUserGroup: this.activeSession.userGroup,
      });

      this.checkForStatus();

      if (this.activeSession.redirectUrl) {
        window.location.href = this.activeSession.redirectUrl;
      }

      this.$store.dispatch('viewer/bindSessionViewers', {
        sessionId: this.activeSession.id,
      });

      this.$store.dispatch('viewer/bindSessionQuestions', {
        sessionId: this.activeSession.connectedSessionId
          ? this.activeSession.connectedSessionId
          : this.activeSession.id,
      });

      this.$store.dispatch('viewer/bindSessionPolls', {
        sessionId: this.activeSession.id,
      });

      this.$store.dispatch('viewer/bindSessionWordcloud', {
        sessionId: this.activeSession.id,
      });

      this.isSetup = true;
    },
    checkForStatus() {
      if (this.isSessionValid && this.activeSession) {
        const now = new Date();
        // TODO: Show error message if no case is fitting
        if (
          now <= this.activeSession.endAt.toDate() ||
          this.activeSession.sessionUnlimited
        ) {
          if (!this.$route.path.includes('tv') && this.activeSession.isPaused) {
            this.$router.push({
              name: 'Viewer Session Paused',
            });
          } else {
            if (now >= this.activeSession.startAt.toDate()) {
              if (
                !(
                  this.$route.path.includes('reactions') ||
                  this.$route.path.includes('stats') ||
                  this.$route.path.includes('info') ||
                  this.$route.path.includes('questions') ||
                  this.$route.path.includes('polls') ||
                  this.$route.path.includes('wordclouds') ||
                  this.$route.path.includes('tv') ||
                  this.$route.path.includes('moderator')
                )
              ) {
                // Go to info tab if reactions are deactivated
                if (
                  (this.activeSession.deactivatedMenuItems &&
                    this.activeSession.deactivatedMenuItems.includes(
                      'reactions'
                    )) ||
                  this.$route.query.hideHeader === 'true'
                ) {
                  this.$router.push({
                    name: 'Viewer Session Info',
                  });
                } else {
                  this.$router.push({
                    name: 'Viewer Session Reactions',
                  });
                }
                if (this.activeSession.requireViewerName) {
                  if (!localStorage.getItem('username')) {
                    const username = prompt(
                      this.$t(
                        'views.viewer.sessionViews.userNamePromptLabel'
                      ) as string
                    );
                    localStorage.setItem('username', username ?? '');
                  }
                }
              }
            } else {
              if (!this.$route.path.includes('waiting-room')) {
                this.$router.push({
                  name: 'Viewer Session Waiting Room',
                });
              }
            }
          }
        } else {
          if (
            this.$route.path.includes('reactions') ||
            this.$route.path.includes('stats') ||
            this.$route.path.includes('info') ||
            this.$route.path.includes('questions') ||
            this.$route.path.includes('polls') ||
            this.$route.path.includes('wordclouds') ||
            this.$route.path.includes('tv') ||
            this.$route.path.includes('moderator')
          ) {
            this.$router.push({
              name: 'Viewer Session Review',
            });
          } else {
            if (
              !this.$route.path.includes('session-ended') &&
              !this.$route.path.includes('review') &&
              !this.$route.path.includes('success')
            ) {
              this.$router.push({
                name: 'Viewer Session Ended',
              });
            }
          }
        }
      }
    },
  },
});
